import { GridColumns, GridRowHeightParams, GridSortModel } from "@mui/x-data-grid";
import { Dispatch } from "react";

export type P_access = {
  accessToken: string;
};

export type LoaderProps = {
  isLoading: boolean;
};

export type ToastTypeProps = {
  open: number | Date;
  message: string;
  type: string;
};

export type Search = {
  searchTerm?: string;
  fromDate?: string;
  toDate?: string;
  merchant?: string;
  status?: string;
  currency?: string;
  applicationName?: string;
  customerType?: string;
};

export type TransactionsType = {
  id?: string;
  transactionId?: string;
  account?: null;
  transactionDate?: null;
  applicationName?: null;
  currency?: string;
  transactionAmount?: string;
  userId?: null;
  transactionStatus?: string;
  cardType?: string;
  referenceCode?: string;
  merchant?: string;
  errorMessage?: string;
};

type PaginationInfo = {
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
};

export type TransactionData = {
  paginationInfo: PaginationInfo;
  records: TransactionsType[] | RefundTransactions[];
};

export type TransactionsResponse = {
  data: TransactionData;
  errors: any;
  isSuccess: boolean;
  message: string;
};

export type RefundTransactions = Omit<
  TransactionsType,
  | "account"
  | "transactionDate"
  | "transactionAmount"
  | "transactionStatus"
  | "cardType"
> & {
  reconciliationId?: string;
  amount?: string;
  status?: string;
  refundDate?: string;
  errorDetail?: string;
};

export type MerchantTypes = {
  label: string;
  value: string;
};

export type DataTableProps = {
  rows: any;
  userFilter?:string[];
  statuses?:string[];
  page: number;
  columns: GridColumns;
  rowCount: number;
  pageSize: number;
  getRowHeight?:(rowData: GridRowHeightParams) => void;
  onPageChange: (newPage: number) => void;
  setPageSize: (value: number) => void;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  setPage: (value: number) => void;
  setSearchText?: Dispatch<any>;
  searchText?: Search | string;
  showtoolbar: boolean;
  pagination?: boolean;
  showcurrency?: boolean;
  showMerchant?: boolean;
  showUser?: boolean;
  showstatus?: boolean;
  showapplication?: boolean;
  datepicker?: boolean;
  transactionType?: string;
  showExport?: boolean;
  isSearchOnly?: boolean;
  showCustomerType?: boolean;
  customerType?: string;
};

export type TransactionGraphData = {
  type: string;
  day: string;
  month: string;
  year: string;
  status: string;
  recordCount: string;
};

export type BarSeriesType = {
  name: string;
  data: Array<number>;
};

export type paymentTransactionDetails = {
  id: number;
  paymentInstrumentId: string;
  customerTokenId: string;
  cardLast4Digits: string;
  referenceInformationCode: string;
  isCapture: boolean;
  totalAmount: number;
  currency: string;
  merchantRef: string;
  ignoreAvsResult: string;
  declineAvsCodes: string;
  applicationName: string;
  paymentCaptureId: string;
  terminalId: string;
  processorApprovalCode: string;
  networkTransactionId: string;
  processorResponseCode: string;
  processorTransactionId: string;
  avsCode: string;
  avsCodeRaw: string;
  errorReason: string;
  errorMessage: string;
  responseInsightsCategory: string;
  responseInsightsCategoryCode: string;
  reconciliationId: string;
  paymentCaptureStatus: string;
  paymentSubmitTime: string;
  cardType: string;
  userId: string;
  account: string;
};

export type refundPaymentTransactionDetails = paymentTransactionDetails & {
  refundAmount?: number;
  refundCurrency?: string;
  refundStatus?: string;
  requestedAmount?: number;
  refundSubmitTime?: string;
  transactionId?: string;
  errorDetail?: string;
  referenceCode?: string;
  salesOrderNumber?: string;
};
export type billTo = {
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  locality: string;
  postalCode: string;
  country: string;
  email: string;
  administrativeArea: string;
  phoneNumber: string;
  addressLine1:string;
};

export type card = {
  number: string;
  expirationMonth: number;
  expirationYear: number;
  type: string;
  cardState: string;
};
export type customerDetails = {
  id: string;
  instrumentObject: string;
  state: string;
  accountNumber: string;
  card: card;
  billTo: billTo;
  creator: string;
};

export type TransactionMaster = {
  paymentTransactionDetails: paymentTransactionDetails;
  customerDetails: customerDetails;
};

export type RefundedTransactionMaster = {
  refundPaymentTransactionDetails: refundPaymentTransactionDetails;
  customerDetails: customerDetails;
};

export type Transaction = {
  data: TransactionMaster;
  errors: any;
  isSuccess: boolean;
  message: string;
};

export type RefundTransaction = Transaction & {
  data: RefundedTransactionMaster;
};

export type SecurityKey = {
  id: number;
  application: string;
  publicKey?: string;
  remarks?: string;
  createdBy?: string;
  createdOn?: string;
  updatedOn?: string;
  updatedBy?: string;
  isActive?: boolean;
};

export type SecurityKeyBody = {
  application: number;
  remarks: string;
};

export type SecurityKeyResponse = {
  data: SecurityKey[];
  errors: any;
  isSuccess: boolean;
  message: string;
};

export enum ApplicationType {
  CCDB = 1,
  JDE = 2,
  MAGENTO = 3,
  MAGENTORETAIL = 4,
}

export enum FiservTransactionType
{
    T = 1,  // TOKENIZATION
    A = 2,  // AUTHORIZATION
    S = 3   // SALE
}

export enum UserRole {
  ADMIN = 1,
  USER,
}

export type FSBaseResponse = {
  accountId: number;
  message: string;
  status: string;
}

export type FSPaymentMethod = {
  paymentMethodId: number;
  type: string;
  last4: string;
  routingNumber: string;
  tokenId: string; // Snap pay tokenid is Token of the card to be used for future requests e.g. Capture/Refund/Authorization 
  firstName: string;
  lastName: string;
  expirationMonth: string;
  expirationYear: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumber: string;
  email: string;
  cardType: string;
  surchargeApplicable: string;
}

export type HostedPageResponse = FSBaseResponse & {
  cardDetails: FSPaymentMethod;
}
export type HppTransactionDetailResponse = {
  data: HppTransactionDetail;
  errors: any;
  isSuccess: boolean;
  message: string;
};

export interface FSTransaction {
  merchantId: string;
  paymentGateway: string;
  returnCode: string;
  returnDescription: string;
  transactionStatus: string;
  pgTransactionId: string;
  paymentTransactionId: string;
  authorizationCode: string;
  transactionDate: Date;
  procRespCode: string;
  scVerification: string;
  addressVerification: string;
  transactionAmount: number;
  currency: string;
  feeAmount: number;
  feeAuthCode: string;
  feeFormat: string;
  feeStatus: string;
  feeTransactionId: string;
  feeType: string;
  feeValue: number;
  threeDSecure: string;
  signature: string;
}

export type HppTransactionDetail = FSBaseResponse & {
  sapUserId :string;
  jdeUserId :string;
  magentoUserId : string;
  paymentMethod: FSPaymentMethod;
  transaction : FSTransaction;
}

// Define the TokenizedCard interface
export interface TokenizedCard {
  id:number;
  customerId:number;
  tokenId: string;
  accountNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cardType: string;
  addressLine1:string;
  locality:string;
  firstName:string;
  lastName: string;
  email: string;
  phoneNumber: string;
  administrativeArea:string;
  postalCode:string;
  country:string;
  note: string;
  isDeleted: any;
  isOneTimeCard: boolean;
  addedBy: string;
}

// Define the CustomerCardResponse interface
export type CustomerCardResponse = {
  isSuccess: boolean;
  message: string;
  errors: string | null;
  data: TokenizedCard[];
}

// Define the TokenizedCard interface
export interface GenericActionResponse {
  message: string;
  status: string;
}

export type DeleteCardResponse = {
  isSuccess: boolean;
  message: string;
  errors: string | null;
  data: GenericActionResponse;
}

export type EditCustomerCardInput = {
  id: number;
  customerId: number;
  tokenId: string;
  accountNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cardType: string;
  firstName:string;
  lastName: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  locality: string;
  administrativeArea: string;
  postalCode: string;
  country: string;
  note: string;
  isDeleted: boolean;
  addedBy: string;
  isOneTimeCard: boolean;
}

// Define a type for an individual ERP item
export interface ErpItem {
  erpTitle: string;
  erpid: string;
};

// Define a type for the API response
export type CustomerERPIdsResponse = {
  isSuccess: boolean;
  message: string;
  errors: any; 
  data: ErpItem[];
};

export interface CustomerDetails  {
  id: number;
  sapUserId: string;
  jdeUserId: string;
  magentoUserId: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  email: string;
  phoneNumber: string;
  d365UserId: string;
};

export type CustomerDetailsResponse = {
  isSuccess: boolean;
  message: string;
  errors: any; 
  data: CustomerDetails;
};


export type CustomerBilltoDetailsResponse = {
  isSuccess: boolean;
  message: string;
  errors: any; 
  data: billTo;
};


export interface Customer {
  customerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  phone?: string;
  email?: string;
};

export type SessionRequestInput ={
  sapUserId: string;
  jdeUserId: string;
  magentoUserId: string;
  isForEcomm: boolean;
  saveForFutureOrders: boolean;
  customer?: Customer;
  orderTotalAmount: number;
  applicationType: ApplicationType;
  transactionType: FiservTransactionType;
  styleCss?: string;
};

//api response generic type
export type ApiResponse<T> = {
  isSuccess: boolean;
  message: string;
  errors: any; 
  data: T;
};

export type CustomerListData = {
  paginationInfo: PaginationInfo;
  records: CustomerDetails[];
};