import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import {
  ApiResponse,
  ApplicationType,
  billTo,
  CustomerDetails,
  EditCustomerCardInput,
  FiservTransactionType,
  GenericActionResponse,
  SessionRequestInput,
  TokenizedCard
} from "../utils/types";
import { AxiosResponse } from "axios";
import fiservService from "../services/fiserv";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Restore as RestoreIcon } from "@mui/icons-material";
import Toast from "../ui-components/Toaster";
import FiservInteropForm from "../ui-components/Fiserv-Interop-Popup";
import "../styles/_savedCards.css";
import { CardFooter } from "react-bootstrap";
import { currentUserRole } from "../ui-components/Access-Validate";
import { USER_ROLES } from "../utils/constants";


export default function CustomerCards({ customerData, applicationName }: { customerData: CustomerDetails, applicationName: string }) {
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [cardData, setCardData] = useState<TokenizedCard[] | null>(null);
  const [selectedCard, setSelectedCard] = useState<TokenizedCard | null>(null);
  const [cardRestoreOpen, setCardRestoreOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [addCardModalOpen, setAddCardModalOpen] = useState(false);
  const [fiservRequestId, setFiservRequestId] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based
  const [hasEditRights, setEditRights] = useState<boolean>(false);

  // Determine final values, giving priority to props, then state, then params
  const sapUserId = customerData?.sapUserId;
  const jdeUserId = customerData?.jdeUserId;
  const magentoUserId = customerData?.magentoUserId;
  const d365UserId = customerData?.d365UserId;

  useEffect(() => {
    // If URL parameters are present, set the ERP title and ID
    if (sapUserId || jdeUserId || magentoUserId || d365UserId) {
      handleGetCards();
    }

    // for retail customer d365userid and magentouserid is not null
    setEditRights(currentUserRole().includes(USER_ROLES.ADMIN));

  }, [sapUserId, jdeUserId, magentoUserId, d365UserId, hasEditRights]);

  const handleGetCards = async () => {
    try {
      const isIncludeDeleted: boolean = true;
      const isIncludeOneTimeCard: boolean = true;
      const res: AxiosResponse<ApiResponse<TokenizedCard[]>> = await fiservService.getSavedCardsDetails(
        sapUserId,
        jdeUserId,
        magentoUserId,
        d365UserId,
        isIncludeDeleted,
        isIncludeOneTimeCard,
        applicationName
      );
      if (res?.status === 200 && res.data.isSuccess) {
        // sort cards, future-use card will come first then one-time card followed by deleted card
        const sortedCards = res.data.data.sort((a, b) => {
          if (a.isDeleted && !b.isDeleted) return 1;
          if (!a.isDeleted && b.isDeleted) return -1;
          if (a.isOneTimeCard && !b.isOneTimeCard) return 1;
          if (!a.isOneTimeCard && b.isOneTimeCard) return -1;
          return 0;
        });

        setCardData(sortedCards);
      } else {
        setToast({
          open: Date.now(),
          message: res.data.message,
          type: "error",
        });
      }
    } catch (err: any) {
      setToast({
        open: Date.now(),
        message: "An error occurred while getting customer's card",
        type: "error",
      });
    }
  };

  //Opens Restore Card confirmation Dialog
  const handleCardRestore = (card: TokenizedCard, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent unwanted event propagation
    setSelectedCard(card);
    setCardRestoreOpen(true);
  };

  //Closing dialog of Delete Card Functionality
  const handleCardRestoreClose = () => {
    setCardRestoreOpen(false);
    setSelectedCard(null);
  };

  //Handles Edit Card Functionality
  const handleSaveCardRestore = async () => {
    if (selectedCard) {
      try {
        const requestBody: EditCustomerCardInput = {
          ...selectedCard,
          isDeleted: false, // make it false to restore card
          addedBy: selectedCard.addedBy
        };        

        // Call the API to update the card details
        const response = await fiservService.updateCard(requestBody);

        // Handle response
        if (response.status === 200 && response.data.isSuccess) {
          // Display success toast message
          setToast({
            open: Date.now(),
            message: response.data.message,
            type: "success",
          });
          // Refresh the card list
          handleGetCards();
        } else {
          // Display error toast message
          setToast({
            open: Date.now(),
            message: response.data.message,
            type: "error",
          });
        }
      } catch (error) {
        // Display error toast message
        setToast({
          open: Date.now(),
          message: "An error occurred while updating card details",
          type: "error",
        });
      } finally {
        // Close the edit dialog
        handleCardRestoreClose();
      }
    }
  };

  //Handles Edit Card Functionality
  const handleSaveEdit = async () => {
    if (selectedCard) {
      try {
        const requestBody: EditCustomerCardInput = {
          ...selectedCard
        };

        // Call the API to update the card details
        const response = await fiservService.updateCard(requestBody);

        // Handle response
        if (response.status === 200 && response.data.isSuccess) {
          // Display success toast message
          setToast({
            open: Date.now(),
            message: response.data.message,
            type: "success",
          });
          // Refresh the card list
          handleGetCards();
        } else {
          // Display error toast message
          setToast({
            open: Date.now(),
            message: response.data.message,
            type: "error",
          });
        }
      } catch (error) {
        // Display error toast message
        setToast({
          open: Date.now(),
          message: "An error occurred while updating card details",
          type: "error",
        });
      } finally {
        // Close the edit dialog
        handleEditClose();
      }
    }
  };



  //Opens Edit Card dialog
  const handleEditCard = (tokenId: string) => {
    const cardToEdit = cardData?.find((card) => card.tokenId === tokenId);
    if (cardToEdit) {
      setSelectedCard(cardToEdit);
      setEditOpen(true);
    }
  };

  //Closing dialog of Edit Card Functionality
  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedCard(null);
  };



  //Opens Delete Card confirmation Dialog
  const handleDeleteCard = (card: TokenizedCard, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent unwanted event propagation
    setSelectedCard(card);
    setOpen(true);
  };

  //Closing dialog of Delete Card Functionality
  const handleClose = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  //Handles Delete Card Functionality
  const confirmDelete = async () => {
    if (selectedCard) {
      try {
        const res: AxiosResponse<ApiResponse<GenericActionResponse>> = await fiservService.deleteCard(
          selectedCard.tokenId,
          sapUserId,
          jdeUserId,
          magentoUserId,
          d365UserId
        );
        if (res?.status === 200 && res.data.isSuccess) {
          setToast({
            open: Date.now(),
            message: res.data.message,
            type: "success",
          });
          handleGetCards();
        } else {
          setToast({
            open: Date.now(),
            message: res.data.message,
            type: "error",
          });
        }
      } catch (err: any) {
        setToast({
          open: Date.now(),
          message: "An error occurred while deleting card ",
          type: "error",
        });
      } finally {
        handleClose();
      }
    }
  };

  const resetFiservAddCardModal = () => {
    setAddCardModalOpen(false);
    setFiservRequestId('');
  }

  const handleAddNewCard = async (saveForFutureOrders: boolean) => {
    const commonCardDetails: SessionRequestInput = {
      sapUserId: sapUserId,
      jdeUserId: jdeUserId,
      magentoUserId: magentoUserId,
      isForEcomm: true,
      saveForFutureOrders: saveForFutureOrders,
      orderTotalAmount: 0,
      applicationType: ApplicationType.CCDB,
      transactionType: FiservTransactionType.A,
      styleCss: null
    };

    try {
      const response = await fiservService.sessionToAddCard(commonCardDetails);
      if (response.data && response.data.data && response.data.data.requestId) {
        if (response.data.data.status == "Y") {
          setAddCardModalOpen(true);
          setFiservRequestId(response.data.data.requestId);
        } else {
          setToast({
            open: Date.now(),
            message: response.data.data.message,
            type: "error",
          });
          resetFiservAddCardModal();
        }
      } else {
        // Show error message
        setToast({
          open: Date.now(),
          message: "Error: Request ID not found in the response.",
          type: "error",
        });
        resetFiservAddCardModal();
      }
    } catch (error) {
      // Handle the error, e.g., show an error message
      setToast({
        open: Date.now(),
        message: "An error occurred while Adding card details",
        type: "error",
      });
      resetFiservAddCardModal();
    }
  };

  return (
    <>
      {/* Add new Card Iframe */}
      {addCardModalOpen && fiservRequestId && (
        <FiservInteropForm
          onClose={resetFiservAddCardModal}
          requestId={fiservRequestId}
          visible={addCardModalOpen}
        />
      )}


      <Card className="mt-3">
        <CardHeader
          title="Cards"
          sx={{ borderBottom: 1, borderBottomColor: grey[300] }}
          action={
            !d365UserId && (
              <Box display="flex">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleAddNewCard(true)} // Pass true for saving card for future use
                  style={{ marginRight: "10px" }}
                  endIcon={<AddIcon />}
                  disabled={!hasEditRights}
                >
                  Add Card
                </Button>
              
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => handleAddNewCard(false)} // Pass false for one time card
                  style={{ marginRight: "10px" }}
                  endIcon={<AddIcon />}
                  disabled={!hasEditRights}
                >
                  Add One-Time Card
                </Button>
              </Box>
            )
          }
        />
        <CardContent>
          {cardData?.length > 0 && (
            <Box display="flex" alignItems="center" mb={2}>
              {/* Saved Card Box */}
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: "green", // Color for normal cards
                  marginRight: 1,
                }}
              />
              <Typography variant="body2" mr={3}>
                Saved Cards
              </Typography>

              {/* One-Time Card Box */}
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: "RoyalBlue", // Color for one-time cards
                  marginRight: 1,
                }}
              />
              <Typography variant="body2" mr={3}>
                One-Time Cards
              </Typography>

              {/* Deleted Card Box */}
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: "Crimson", // Color for deleted cards
                  marginRight: 1,
                }}
              />
              <Typography variant="body2">
                Deleted Cards
              </Typography>
            </Box>
          )}

          <div className="cards-container">
            {cardData?.length > 0 ? (
              <>
              {cardData?.map((card, index) => (
                <div 
                  className={card.isDeleted 
                    ? "credit-card-deleted" 
                    : card.isOneTimeCard 
                    ? "credit-card-onetimeuse" 
                    : "credit-card"}  
                  key={index}
                >
                  <div className="card-header">
                    <div className="credit-card-chip"></div>
                    <div className="card-type">{card.cardType?.toUpperCase()}</div>
                  </div>
                  <p className="credit-card-number">**** **** **** {card.accountNumber.slice(-4)}</p>
                  <div className="credit-card-details">
                    <p><strong>Cardholder: </strong>{card.firstName} {card.lastName}</p>
                    <p><strong>Expiry: </strong>{card.expirationMonth}/{card.expirationYear}</p>
                    <p className="word-break"><strong>Address: </strong>
                      <span>{card.addressLine1} </span>
                      <span>{card.locality} {card.administrativeArea} </span>
                      <span>{card.postalCode} {card.country}</span>
                    </p>
                    <p><strong>Added By: </strong>{card.addedBy || 'NA'}</p>
                    <p className="word-break"><strong>Note: </strong>{card.note}</p>
                  </div>

                  {!d365UserId && !card.isDeleted && (
                    <div className="card-actions">
                      <Tooltip title="Edit Card">
                        <span>
                          <IconButton 
                            className="card-icon" 
                            aria-label="edit" 
                            onClick={() => handleEditCard(card.tokenId)}
                            disabled={!hasEditRights}
                          >
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Card">
                        <span>
                          <IconButton 
                            className="card-icon" 
                            aria-label="delete" 
                            onClick={(e) => handleDeleteCard(card, e)}
                            disabled={!hasEditRights}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  )}

                  {!d365UserId && card.isDeleted && (
                    <div className="card-actions">
                      <Tooltip title="Restore Card">
                        <span>
                          <IconButton 
                            className="card-icon" 
                            aria-label="restore"
                            onClick={(e) => handleCardRestore(card, e)}
                            disabled={!hasEditRights}
                          >
                            <RestoreIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              ))}
              </>
            ) : (
              <Typography variant="body1" color="textSecondary">
                No card details found.
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>



      {/* Dialog for Delete card */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Card</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete the card{" "}
            {selectedCard?.cardType?.toUpperCase()} - {selectedCard?.accountNumber.slice(-4)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Restore card */}
      <Dialog open={cardRestoreOpen} onClose={handleCardRestoreClose}>
        <DialogTitle>Restore Card</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to restore the card{" "}
            {selectedCard?.cardType?.toUpperCase()} - {selectedCard?.accountNumber.slice(-4)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCardRestoreClose} color="primary">
            No
          </Button>
          <Button onClick={handleSaveCardRestore} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>


      {/* Dialog for Edit card */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Card</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Card Type"
              value={selectedCard?.cardType?.toUpperCase() || ""}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Account Number"
              value={selectedCard?.accountNumber || ""}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Expiration Month</InputLabel>
            <Select
              value={selectedCard?.expirationMonth || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  expirationMonth: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            >
              {Array.from({ length: 12 }, (_, index) => index + 1).map(
                (month) => (
                  <MenuItem
                    key={month}
                    value={month}
                    disabled={Number(selectedCard?.expirationYear) === currentYear && month < currentMonth}
                  >
                    {month}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Expiration Year</InputLabel>
            <Select
              value={selectedCard?.expirationYear || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  expirationYear: e.target.value,
                  expirationMonth: selectedCard?.expirationMonth && Number(e.target.value) === currentYear && Number(selectedCard?.expirationMonth) < currentMonth ? '' : selectedCard.expirationMonth,
                } as TokenizedCard)
              }
              fullWidth
            >
              {Array.from({ length: 20 }, (_, index) => {
                const currentYear = new Date().getFullYear();
                const year = currentYear + index;
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="First Name"
              value={selectedCard?.firstName || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  firstName: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Last Name"
              value={selectedCard?.lastName || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  lastName: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone"
              value={selectedCard?.phoneNumber || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  phoneNumber: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              value={selectedCard?.email || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  email: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Address Line 1"
              value={selectedCard?.addressLine1 || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  addressLine1: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Locality"
              value={selectedCard?.locality || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  locality: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Administrative Area"
              value={selectedCard?.administrativeArea || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  administrativeArea: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Postal Code"
              value={selectedCard?.postalCode || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  postalCode: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Country"
              value={selectedCard?.country || ""}
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  country: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Note"
              value={selectedCard?.note || ""}
              placeholder="e.g. Use this card only for orders below $5k"
              onChange={(e) =>
                setSelectedCard({
                  ...selectedCard,
                  note: e.target.value,
                } as TokenizedCard)
              }
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary" disabled={!selectedCard?.expirationMonth}>
            Save
          </Button>
        </DialogActions>
      </Dialog>


      {/* Toast for success and failure */}
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}